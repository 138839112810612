// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-templates-landing-page-tsx": () => import("./../src/templates/landingPage.tsx" /* webpackChunkName: "component---src-templates-landing-page-tsx" */),
  "component---src-pages-404-tsx": () => import("./../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-index-tsx": () => import("./../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-privacy-mdx": () => import("./../src/pages/privacy.mdx" /* webpackChunkName: "component---src-pages-privacy-mdx" */),
  "component---src-pages-terms-mdx": () => import("./../src/pages/terms.mdx" /* webpackChunkName: "component---src-pages-terms-mdx" */)
}

